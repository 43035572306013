import React, { useState, forwardRef } from 'react';
import { Outlet } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
// hooks
import useResponsive from '../../hooks/useResponsive';
import Navbar from './Navbar';
import { assetBucketLink } from '../../config';

// ----------------------------------------------------------------------

const MainStyle = styled('main')(({ theme }) => ({
  flexGrow: 1,
  display: 'flex',
  width: '100%',
  height: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  paddingBottom: '50px',
}));

// ----------------------------------------------------------------------

const GuestLayout = () => {
  const isDesktop = useResponsive('up', 'lg');

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: 'auto',
        minHeight: '100%',
        backgroundImage: `url("${assetBucketLink}/home/BG.jpg")`,
        overflowX: 'hidden',
        position: 'relative',
        zIndex: 1,
      }}
    >
      <div className="marquee">
        <p style={{ whiteSpace: 'nowrap' }}>
          <img
            src="https://odds-prediction.s3.ap-southeast-1.amazonaws.com/home/announcement.webp"
            alt="Marquee logo"
            style={{ width: 20, height: 20, marginRight: 5 }}
          />
          Play at the best online casino - More than 1000 popular casino games, slot games, sport games, card games, and
          fishing games for you.
        </p>
      </div>
      <Navbar />
      <MainStyle>
        <Outlet />
      </MainStyle>
    </Box>
  );
};

export default GuestLayout;
