import { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Button, Box, Stack, AppBar, Toolbar, Typography, Container, Chip, Avatar, alpha } from '@mui/material';
// hooks
import useOffSetTop from '../../../hooks/useOffSetTop';
import useResponsive from '../../../hooks/useResponsive';
// utils
import cssStyles from '../../../utils/cssStyles';
// config
import { HEADER, NAVBAR, PRODUCT_TYPES } from '../../../config';
// components
import Iconify from '../../../components/Iconify';
import { IconButtonAnimate } from '../../../components/animate';
//
import AccountPopover from './AccountPopover';
import NotificationsPopover from './NotificationsPopover';
import GradientButton, { GRADIENT_BUTTON_STYLE } from '../../../components/GradientButton';
import { useDispatch, useSelector } from '../../../redux/store';
import { fNumberDecimal } from '../../../utils/formatNumber';
import { openCoinPackageForm } from '../../../redux/slices/lookup';
import CustomModal from '../../../components/CustomModal';
import CoinPackageForm from './CoinPackageForm';
import useAuth from '../../../hooks/useAuth';
import { PATH_CARD, PATH_CASINO, PATH_SLOT, PATH_SPORT } from '../../../routes/paths';
import GradientBorderCard from '../../../components/GradientBorderCard';
import { XLD_DAILY_ALERT_PATHS } from '..';
import { calculateRemainingDays } from '../../../utils/formatTime';
import ShadowedText from '../../../components/ShadowedText';

// ----------------------------------------------------------------------

const RootStyle = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'isCollapse' && prop !== 'isOffset' && prop !== 'verticalLayout',
})(({ theme }) => ({
  ...cssStyles(theme).bgBlur(),
  boxShadow: 'none',
  height: 'auto',
  zIndex: theme.zIndex.appBar + 1,
  position: 'sticky',
  width: '100%',
  overflowX: 'hidden',
  transition: theme.transitions.create(['width', 'height'], {
    duration: theme.transitions.duration.shorter,
  }),
  borderRadius: 0,
  [theme.breakpoints.up('lg')]: {
    height: 'auto',
    // height: HEADER.DASHBOARD_DESKTOP_HEIGHT,
    // width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH + 1}px)`,
    // ...(isCollapse && {
    //   width: `calc(100% - ${NAVBAR.DASHBOARD_COLLAPSE_WIDTH}px)`,
    // }),
    // ...(isOffset && {
    //   height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
    // }),
    // ...(verticalLayout && {
    //   width: '100%',
    //   height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
    //   backgroundColor: theme.palette.background.default,
    // }),
  },
}));

// ----------------------------------------------------------------------

DashboardHeader.propTypes = {
  onOpenSidebar: PropTypes.func,
  isCollapse: PropTypes.bool,
  verticalLayout: PropTypes.bool,
};

const BACK_BTN_ROUTES = [
  PATH_SPORT.sportPrediction,
  PATH_CARD.slotPrediction,
  PATH_CASINO.casinoPrediction,
  PATH_CASINO.casinoRoom,
  PATH_SLOT.slotPrediction,
];

export default function DashboardHeader({ onOpenSidebar, isCollapse = false, verticalLayout = false }) {
  const isOffset = useOffSetTop(HEADER.DASHBOARD_DESKTOP_HEIGHT) && !verticalLayout;

  const { user } = useAuth();

  const isDesktop = useResponsive('up', 'sm');

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const location = useLocation();

  const isMobile = useResponsive('down', 'sm');

  const { userInfo, isOpenCoinPackageForm, redeemed } = useSelector((x) => x.lookup);

  const isShowBackButton = useMemo(() => {
    let isShow = false;
    BACK_BTN_ROUTES.forEach((x) => {
      if (location.pathname.indexOf(x) !== -1) {
        isShow = true;
      }
    });
    return isShow;
  }, [location]);

  return (
    <RootStyle isCollapse={isCollapse} isOffset={isOffset} verticalLayout={verticalLayout}>
      <div className="marquee">
        <p style={{ whiteSpace: 'nowrap' }}>
          <img
            src="https://odds-prediction.s3.ap-southeast-1.amazonaws.com/home/announcement.webp"
            alt="Marquee logo"
            style={{ width: 20, height: 20, marginRight: 5 }}
          />
          Play at the best online casino - More than 1000 popular casino games, slot games, sport games, card games, and
          fishing games for you.
        </p>
      </div>
      <Toolbar
        sx={{
          minHeight: '100% !important',
          px: { xl: 0, lg: 0, md: 0, sm: 0, xs: 0 },
          py: { xl: 2, lg: 2, md: 2, sm: 2, xs: 2 },
        }}
      >
        <Box sx={{ width: '100%', display: 'flex', mx: 'auto' }} maxWidth={'lg'}>
          {/* {isDesktop && verticalLayout && <Logo sx={{ mr: 2.5 }} />} */}

          {/* <Searchbar /> */}

          {!isDesktop && XLD_DAILY_ALERT_PATHS.join(',').indexOf(location.pathname) === -1 && (
            <IconButtonAnimate onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
              <Iconify icon="eva:menu-2-fill" />
            </IconButtonAnimate>
          )}

          {isShowBackButton && (
            <GradientButton
              onClick={() => navigate(-1)}
              borderRadius="50%"
              noBoxShadow={true}
              sx={{ minWidth: 'unset', width: { xs: 30, sm: 40 }, height: { xs: 30, sm: 40 }, m: 0, p: 0 }}
              BoxStyles={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Iconify icon={'icon-park-solid:back'} />
            </GradientButton>
          )}

          <Box
            sx={{ display: { xl: 'block', lg: 'block', md: 'block', sm: 'none', xs: 'none' }, cursor: 'pointer' }}
            onClick={() => navigate('/')}
          >
            <img
              src="https://odds-prediction.s3.ap-southeast-1.amazonaws.com/home/bringmefly-gif.gif"
              alt="App Logo"
              style={{ height: 60, width: 'auto', objectFit: 'contain' }}
            />
          </Box>

          <Box
            sx={{
              display: { xl: 'none', lg: 'none', md: 'none', sm: 'block', xs: 'block' },
              pl: { xl: 0, lg: 0, md: 0, sm: 2, xs: 0 },
              height: { xl: '60px', lg: '60px', md: '60px', sm: '60px', xs: '10vw' },
              cursor: 'pointer',
            }}
            onClick={() => navigate('/')}
          >
            <img
              src="https://odds-prediction.s3.ap-southeast-1.amazonaws.com/home/bringmefly-logo-small.webp"
              alt="App Logo"
              style={{ height: '100%', width: 'auto' }}
            />
          </Box>

          <Box sx={{ flexGrow: 1 }} />

          <Stack direction="row" alignItems="center" spacing={{ xs: 1.2, sm: 1.5 }}>
            {redeemed[0]?.redemptionExpiry ? (
              <div style={{ position: 'relative' }}>
                <GradientBorderCard
                  borderRadius={30}
                  borderSize={2}
                  borderColor="blue"
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: { xs: '2px 5px', sm: '5px 10px' },
                    position: 'relative',
                  }}
                >
                  <Box sx={{ width: { xs: 15, sm: 20 }, height: { xs: 15, sm: 20 } }}>
                    <img
                      src="https://odds-prediction.s3.ap-southeast-1.amazonaws.com/home/coin_logo.webp"
                      alt="Coin Icon"
                      style={{ width: '100%', height: '100%', background: 'none' }}
                    />
                  </Box>
                  {isDesktop ? (
                    <Typography
                      fontWeight={900}
                      fontStyle="italic"
                      sx={{ m: '0 5px' }}
                      // fontSize={'15px'}
                      fontSize={{ xs: '12px', sm: '15px' }}
                    >
                      Days Remaining:{' '}
                      {redeemed[0]?.redemptionExpiry ? calculateRemainingDays(redeemed[0]?.redemptionExpiry) : 0}
                      {calculateRemainingDays(redeemed[0]?.redemptionExpiry || new Date()) > 1 ? ' days' : ' day'}
                    </Typography>
                  ) : (
                    <Typography
                      fontWeight={900}
                      fontStyle="italic"
                      sx={{ m: '0 5px' }}
                      // fontSize={'15px'}
                      fontSize={{ xs: '12px', sm: '15px' }}
                    >
                      {redeemed[0]?.redemptionExpiry ? calculateRemainingDays(redeemed[0]?.redemptionExpiry) : 0}
                      {calculateRemainingDays(redeemed[0]?.redemptionExpiry || new Date()) > 1 ? ' Days ' : ' Day '}
                      Remaining
                    </Typography>
                  )}
                </GradientBorderCard>

                {/* <Box
                  onClick={() => dispatch(openCoinPackageForm(true))}
                  sx={{
                    width: { xs: 13, sm: 15 },
                    height: { xs: 13, sm: 15 },
                    position: 'absolute',
                    right: -5,
                    top: '30%',
                    cursor: 'pointer',
                    zIndex: 99999,
                    WebkitTransform: 'translate3d(0,0,1px)',
                    transform: 'translate3d(0,0,1px)',
                  }}
                >
                  <img
                    src="https://odds-prediction.s3.ap-southeast-1.amazonaws.com/home/plus_icon.png"
                    alt="Plus Icon"
                    style={{ width: '100%', zIndex: 99999 }}
                  />
                </Box> */}
              </div>
            ) : (
              <GradientButton
                onClick={() => dispatch(openCoinPackageForm(true))}
                noBoxShadow={true}
                sx={{ minWidth: 'unset', m: 0, px: 1 }}
                BoxStyles={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <ShadowedText fontSize={{ xs: 11, sm: 13, md: 14 }}>Subscribe Now</ShadowedText>
              </GradientButton>
            )}

            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <NotificationsPopover />

              {!isMobile && (
                <>
                  <IconButtonAnimate
                    color={'primary'}
                    // onClick={handleOpen}
                    size="small"
                    sx={{ width: 35, height: 35, mx: 0 }}
                  >
                    <img
                      src="https://odds-prediction.s3.ap-southeast-1.amazonaws.com/home/settings_logo.webp"
                      alt="Settings"
                      style={{ height: '100%', width: '100%' }}
                    />
                  </IconButtonAnimate>
                  <IconButtonAnimate
                    color={'primary'}
                    size="small"
                    // onClick={handleOpen}
                    sx={{ width: 35, height: 35, mx: 0 }}
                  >
                    <img
                      src="https://odds-prediction.s3.ap-southeast-1.amazonaws.com/home/support_logo.webp"
                      alt="Support"
                      style={{ height: '100%', width: '100%' }}
                    />
                  </IconButtonAnimate>
                </>
              )}
            </Box>

            <Stack direction="column" sx={{ display: { xl: 'flex', lg: 'flex', md: 'flex', sm: 'flex', xs: 'none' } }}>
              <Typography fontWeight={700} fontStyle="italic" fontSize={{ xs: '11px', sm: '15px' }}>
                Hello
              </Typography>
              <Typography fontWeight={700} fontStyle="italic" fontSize={{ xs: '11px', sm: '15px' }}>
                {userInfo?.displayName || user?.displayName || 'Player Name'}
              </Typography>
            </Stack>
            <AccountPopover />
          </Stack>
        </Box>
      </Toolbar>
      <CustomModal
        fullWidth
        maxWidth={'md'}
        open={isOpenCoinPackageForm}
        onClose={() => dispatch(openCoinPackageForm(false))}
        isBackdropClosable={false}
      >
        <CoinPackageForm onSuccess={() => dispatch(openCoinPackageForm(false))} />
      </CustomModal>
    </RootStyle>
  );
}
