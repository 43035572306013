import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// @mui
import { styled, useTheme } from '@mui/material/styles';
import { Box, Stack, Drawer, Button } from '@mui/material';
// hooks
import useResponsive from '../../../hooks/useResponsive';
import useCollapseDrawer from '../../../hooks/useCollapseDrawer';
// utils
import cssStyles from '../../../utils/cssStyles';
// config
import { NAVBAR } from '../../../config';
// components
import Logo from '../../../components/Logo';
import Scrollbar from '../../../components/Scrollbar';
import { NavSectionVertical } from '../../../components/nav-section';
//
import navConfig from './NavConfig';
import NavbarDocs from './NavbarDocs';
import NavbarAccount from './NavbarAccount';
import CollapseButton from './CollapseButton';
import { PROD_ROUTES } from '../header/SecondNavbar';
import GradientButton from '../../../components/GradientButton';
import ShadowedText from '../../../components/ShadowedText';
import Iconify from '../../../components/Iconify';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.shorter,
    }),
  },
}));

// ----------------------------------------------------------------------

NavbarVertical.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function NavbarVertical({ isOpenSidebar, onCloseSidebar }) {
  const theme = useTheme();

  const { pathname } = useLocation();

  const navigate = useNavigate();

  const { isCollapse, collapseClick, collapseHover, onToggleCollapse, onHoverEnter, onHoverLeave } =
    useCollapseDrawer();

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      <Stack
        spacing={3}
        sx={{
          pt: 3,
          pb: 2,
          px: 2.5,
          flexShrink: 0,
          ...(isCollapse && { alignItems: 'center' }),
        }}
      >
        <GradientButton
          onClick={onCloseSidebar}
          borderRadius="50%"
          noBoxShadow={true}
          sx={{ minWidth: 'unset', p: 1.4, m: 0 }}
          BoxStyles={{ width: '100%', display: 'flex', justifyContent: 'end' }}
        >
          <Iconify icon={'icon-park-solid:back'} />
        </GradientButton>
        {PROD_ROUTES.map((x, idx) => {
          if (pathname.indexOf(x.path) !== -1) {
            return (
              <GradientButton
                key={idx}
                onClick={() => navigate(x.path)}
                BoxStyles={{ width: '100%', display: 'flex', justifyContent: 'center' }}
              >
                <ShadowedText>{x.title}</ShadowedText>
              </GradientButton>
            );
          }
          return (
            <Button key={idx} sx={{}} onClick={() => navigate(x.path)}>
              <ShadowedText>{x.title}</ShadowedText>
            </Button>
          );
        })}
        <Stack direction={'row'} spacing={2} sx={{ justifyContent: 'center' }}>
          {/* <img
            src="https://odds-prediction.s3.ap-southeast-1.amazonaws.com/home/message.png"
            alt="Notification"
            style={{ height: 25, width: 'auto' }}
          /> */}

          <img
            src="https://odds-prediction.s3.ap-southeast-1.amazonaws.com/home/settings_logo.webp"
            alt="Settings"
            style={{ height: 25, width: 'auto', marginLeft: 5 }}
          />

          <img
            src="https://odds-prediction.s3.ap-southeast-1.amazonaws.com/home/support_logo.webp"
            alt="Support"
            style={{ height: 25, width: 'auto', marginLeft: 5 }}
          />
        </Stack>
      </Stack>

      {/* <NavSectionVertical navConfig={navConfig} isCollapse={isCollapse} /> */}

      {/* <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between' }}>

      </Box> */}
    </Scrollbar>
  );

  return (
    <RootStyle
      sx={{
        width: {
          lg: isCollapse ? NAVBAR.DASHBOARD_COLLAPSE_WIDTH : NAVBAR.DASHBOARD_WIDTH,
        },
        ...(collapseClick && {
          position: 'absolute',
        }),
      }}
    >
      <Drawer
        open={isOpenSidebar}
        onClose={onCloseSidebar}
        PaperProps={{
          sx: { ...cssStyles(theme).bgBlur({ color: '#0A0B24', opacity: 0.5 }), width: NAVBAR.DASHBOARD_WIDTH },
        }}
      >
        {renderContent}
      </Drawer>
    </RootStyle>
  );
}
